import { render, staticRenderFns } from "./ExitCapture.vue?vue&type=template&id=b24b7f3a"
import script from "./ExitCapture.vue?vue&type=script&lang=ts"
export * from "./ExitCapture.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RkOverlay: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkOverlay/RkOverlay.vue').default,RkPicture: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkPicture/RkPicture.vue').default,RkCloseButton: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkCloseButton/RkCloseButton.vue').default})
