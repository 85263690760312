





















































import {
  ref,
  onBeforeUnmount,
  defineComponent,
  useFetch,
  onMounted,
  useContext,
  computed,
} from '@nuxtjs/composition-api';
import { useStoryblokStore } from '~/modules/storyblok/stores/storyblok';
import { getStoryLinkPath } from '~/modules/storyblok/helpers/getStoryLinkPath';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default defineComponent({
  name: 'ExitCapture',
  setup() {
    const { app } = useContext();
    const exitModal = ref(null);
    const exitModalVisible = ref(false);
    const storyblok = useStoryblokStore();
    const exitCapture = ref(null);
    const exitCaptureEnabled = ref(null);
    const exitCaptureImages = ref(null);
    const exitCaptureLink = ref(null);
    const dimensions = ref({ width: '', height: '' });
    const wrapper = computed(() => {
      return exitCaptureLink.value.cached_url ? 'RkLink' : 'div';
    });

    useFetch(async () => {
      // Storyblok content
      await storyblok.load();
      exitCapture.value = storyblok.global.content.exitCapture[0];
      exitCaptureEnabled.value = exitCapture.value.exitCaptureEnabled;
      exitCaptureImages.value = exitCapture.value.exitCaptureImage;
      exitCaptureLink.value = exitCapture.value.exitCaptureLink;
    });

    const showModal = () => {
      setTimeout(() => {
        exitModalVisible.value = true;
        disableBodyScroll(exitModal.value);
        // set cookie for 1 week so we don't keep seeing the exit capture modal
        app.$cookies.set('vsf-exit-capture-seen', true, {
          maxAge: 60 * 60 * 24 * 7,
          sameSite: 'strict',
          path: '/',
          secure: true,
        });
      }, 1000);
    };

    const closeModal = () => {
      exitModalVisible.value = false;
      if (app.$device.isDesktop) {
        document.documentElement.removeEventListener('mouseleave', handleMouseLeave);
      }
      if (app.$device.isMobile) {
        document.documentElement.removeEventListener('scroll', handleScroll);
      }
      clearAllBodyScrollLocks();
    };

    const handleMouseLeave = () => {
      showModal();
      document.documentElement.removeEventListener('mouseleave', handleMouseLeave);
    };

    const handleScroll = () => {
      // Calculate the scroll position at 50% of the page's height
      let scrollTrigger = document.body.scrollHeight * 0.5;
      let scrollY = window.scrollY || window.pageYOffset;

      if (scrollY > scrollTrigger) {
        showModal();
        document.removeEventListener('scroll', handleScroll);
      }
    };

    onMounted(() => {
      if (app.$cookies.get('vsf-exit-capture-seen') || !exitCaptureEnabled.value) {
        return;
      }
      if (app.$device.isDesktop) {
        document.documentElement.addEventListener('mouseleave', handleMouseLeave);
      }
      if (app.$device.isMobile) {
        document.addEventListener('scroll', handleScroll);
      }
    });

    onBeforeUnmount(() => {
      closeModal();
    });

    return {
      closeModal,
      exitCaptureEnabled,
      exitModal,
      exitModalVisible,
      exitCaptureImages,
      exitCaptureLink,
      getStoryLinkPath,
      dimensions,
      exitCapture,
      wrapper,
    };
  },
});
