var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.exitCaptureEnabled)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.exitModalVisible),expression:"exitModalVisible"}]},[_c('transition',{attrs:{"enter-active-class":"duration-300 ease-out","enter-class":"scale-90 opacity-0","enter-to-class":"scale-100 opacity-100","leave-active-class":"duration-300 ease-in","leave-class":"scale-100 opacity-100","leave-to-class":"scale-90 opacity-0"}},[_c('div',{staticClass:"fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center"},[_c('RkOverlay',{attrs:{"visible":_vm.exitModalVisible},on:{"click":_vm.closeModal}}),_vm._v(" "),_c('div',{ref:"exitModal",staticClass:"relative z-50"},[_c(_vm.wrapper,{tag:"component",attrs:{"link":_vm.exitCaptureLink
                ? _vm.getStoryLinkPath(_vm.exitCaptureLink.cached_url, _vm.exitCaptureLink.linktype)
                : '',"target":_vm.exitCaptureLink.target}},[_c('RkPicture',{attrs:{"src":_vm.exitCaptureImages[0].image.filename || '',"responsiveImages":{
                mobile: {
                  default: _vm.exitCaptureImages[0].mobileImage.filename || '',
                  retina: _vm.exitCaptureImages[0].mobileImageRetina.filename || '',
                  width: _vm.exitCaptureImages[0].mobileImageWidth,
                  height: _vm.exitCaptureImages[0].mobileImageHeight,
                },
                desktop: {
                  default: _vm.exitCaptureImages[0].desktopImage.filename || '',
                  retina: _vm.exitCaptureImages[0].desktopImageRetina.filename || '',
                },
              },"alt":_vm.exitCaptureImages[0].image.alt,"width":_vm.exitCaptureImages[0].imageWidth,"height":_vm.exitCaptureImages[0].imageHeight,"loading":'eager'}})],1),_vm._v(" "),_c('div',{staticClass:"absolute right-2.5 top-2.5"},[_c('RkCloseButton',{attrs:{"aria-label":"Close","darkTheme":""},on:{"click":_vm.closeModal}})],1)],1)],1)])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }